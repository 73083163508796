var lightboxJS = {};

( function($){

    lightboxJS.Gallery = function(){

        var body = $( 'body' );
        var current, size;

        $( '.js-lightbox' ).find( 'a' ).addClass( 'lightboxTrigger' );
        $( '.lightboxTrigger' ).on( 'click', function(e) {

        e.preventDefault();

        var image_href = $( this ).attr( 'href' );
        var slideNum = $( '.lightboxTrigger' ).index( this );

        if ( $( '#lightbox' ).length > 0 ) {

            $( '#lightbox' ).fadeIn( 300 );

        } else {

            var lightbox =
                '<div id="lightbox">' +
                '<div id="slideshow">' +
                '<span class="lightbox-close">&times;</span>' +
                '<ul></ul>' +
                '<div class="lightbox-nav">' +
                '<a href="#prev" class="lightbox-controls lightbox-prev fa fa-arrow-left"></a>' +
                '<a href="#next" class="lightbox-controls lightbox-next fa fa-arrow-right"></a>' +
                '</div>' +
                '</div>' +
                '</div>';

            body.append( lightbox );

            $( '.js-lightbox' ).find( '.lightboxTrigger' ).each( function() {
                var $href = $( this ).attr( 'href' );
                $( '#slideshow ul' ).append(
                    '<li class="center-image">' +
                    '<img src="' + $href + '">' +
                    '</li>'
                );
            });

        }

        size = $('#slideshow ul > li').length;

        $('#slideshow ul > li').hide();
        $('#slideshow ul > li:eq(' + slideNum + ')').show();

        current = slideNum;

        });

        $('body').on('click', '#lightbox', function() {
          $('#lightbox').fadeOut(300);
        });

          body.on('click', '.lightbox-controls', function(e) {

            e.preventDefault();
            e.stopPropagation();

            var $this = $(this);
            var dest;

            if ($this.hasClass('prev')) {
              dest = current - 1;
              if (dest < 0) {
                dest = size - 1;
              }
            } else {
              dest = current + 1;
              if (dest > size - 1) {
                dest = 0;
              }
            }

            $('#slideshow ul > li:eq(' + current + ')').fadeOut(750);
            $('#slideshow ul > li:eq(' + dest + ')').fadeIn(750);

            current = dest;
          });

    };

    lightboxJS.Gallery();

}) (jQuery);
