var ctaJS = {};

( function($){

    var headerBanner = $( '.header-banner' );
    var body = $( 'body' );

    ctaJS.redantduntCta = function(){
        var bannerHeight = headerBanner.outerHeight();

        function ctaVisibility(){
            if ( $( window ).scrollTop() > bannerHeight ) {
                body.addClass( 'is-cta-show' );
            }else{
                body.removeClass( 'is-cta-show' );
            }
        }
        ctaVisibility();

        $( window ).on( 'scroll', function(){
            ctaVisibility();
        });
    };

    ctaJS.Init = function(){
        setTimeout( function(){
            ctaJS.redantduntCta();
        }, 1000 );
    };

    ctaJS.Init();

    $( window ).on({
        resize: function(){
            setTimeout( function(){
                if ( headerBanner.length ) {
                    ctaJS.redantduntCta();
                }
            }, 500 );
        }
    });

}) (jQuery);
