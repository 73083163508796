var carouselJS = {};

( function($){

    carouselJS.Setup = function(){
        var inner = $( '.carousel-inner' );
        var $container = $( '.js-custom-gallery' );

        $container.find( '.gallery-item' ).unwrap();
        $container.find( '.gallery-icon' ).addClass( 'center-image' );
        inner.find( 'figure' ).addClass( 'item' );

        $.each( inner.find( 'figure' ), function( index, value ){

            var _this = $( value );
            _this.attr( 'data-index', _this.index() );

            if ( _this.attr( 'data-index' ) < 1 ) {
                _this.addClass( 'active' );
            }

        });

        $.each( inner, function( index, value ){

            var _this = $( value );
            if ( _this.find( '.item' ).length === 1 ) {
                _this.parents( '.carousel' ).find( '.carousel-control' ).remove();
            }

        });

    };

    carouselJS.switchtoBG = function(){
        $.each( $( '.js-switch-bg .gallery-item' ), function( index, value ){

            var _this = $( value );
            var target = _this.find( 'img' );

            target.remove();
            _this.addClass( 'is-full-parallax js-parallax' )
                 .attr({ 'data-speed':'0.50' })
                 .css({ 'background-image':'url( ' + target.attr( 'src' ) + ' )' });

        });
    };

    carouselJS.switchtoBG();

    $( window ).on({
        load: function(){
            setTimeout( function(){
                 carouselJS.Setup();
            }, 500 );
        }
    });

}) (jQuery);
