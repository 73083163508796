var modalJS = {};

( function($){

    $container = $( '.js-modal' );
    target = $container.find( 'iframe' );

    modalJS.youtubeControls = function(){

        $container.on('hidden.bs.modal', function (e) {
            target.attr( 'src',target.attr( 'src' ) );
        });

    };

    modalJS.youtubeControls();

}) (jQuery);
