var gridJS = {};

( function($){

    $container = $( '.grid' );

    gridJS.Setup = function(){
        $container.find( 'figure' ).unwrap();
        $container.find( 'figure' ).addClass( 'col-xs-6 col-sm-3' );
        $container.find( '.gallery-icon' ).addClass( 'center-image' );
    };

    $( window ).on({
        load: function(){
            setTimeout( function(){
                gridJS.Setup();
            }, 500 );
        }
    });

}) (jQuery);
