var animateJS = {};

( function($){

    $.fn.parallax = function( options ) {

		var windowHeight = $( window ).height();

			var settings = $.extend({
			speed        : 0.15
			}, options);

		return this.each( function() {

			var $this = $( this );

			function BackgroundPosition(){
				var scrollTop = $( window ).scrollTop();
				var offset = $this.offset().top;
				var height = $this.outerHeight();

				if ( offset + height <= scrollTop || offset >= scrollTop + windowHeight ) {
					return;
				}

				var yBgPosition = Math.round( (offset - scrollTop ) * settings.speed );
				if( Viewport.documentWidth().width >= 768 ){
					$this.css( 'background-position', 'center ' + yBgPosition + 'px' );
				}else{
					$this.css( 'background-position', 'center center' );
				}
			}

			$( window ).on( 'resize', function(){
				BackgroundPosition();
			});

			$( document ).scroll(function(){
				BackgroundPosition();
			});

		});

	};

    animateJS.Parallax = function(){
        $( '.js-parallax' ).each( function(){
			var dataSpeed = $( this ).data( 'speed' );
			$( this ).parallax({
				speed : dataSpeed
			});
		});
    };

    animateJS.ScrollFade = function(){
        var $html = $( 'html' );
        var scrollAnimate = $( '.js-scroll-animate' );

        if( Viewport.documentWidth().width < 767 ){
            $html.removeClass( 'js-scroll-animate' );
            $( '.is-scroll' ).removeClass( 'is-scroll is-bottom-top is-left-right is-right-left' );
        }else{
            if ( $html.hasClass( 'js-scroll-animate' ) ) {
                $html.addClass( 'js-scroll-animate' );
            }else{
                $html.removeClass( 'js-scroll-animate' );
                $( '.is-scroll' ).removeClass( 'is-scroll is-bottom-top is-left-right is-right-left' );
            }
        }

        $.each( scrollAnimate.find( '.is-scroll' ), function( index, value ){
            var _this = $( value );
            var elPos = _this.offset().top;

            if ( $( window ).scrollTop() > elPos - ( $( window ).outerHeight() / 6 ) * 5 ) {
                _this.addClass( 'is-enable' );
            }

        });

    };

    animateJS.Onload = function(){

        animateJS.Parallax();
        animateJS.ScrollFade();

        $animate = $( '.page-start' );

        setTimeout( function(){
            $animate.find( '.is-animate' ).addClass( 'is-enable' );
        }, 1000 );

    };

    $( window ).on({
        load: function(){
            animateJS.Onload();
        },
        resize: function(){
            animateJS.ScrollFade();
        },
        scroll: function(){
            animateJS.ScrollFade();
        }
    });

}) (jQuery);
