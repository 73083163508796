var ieJS = {};

( function($){

    ieJS.init = function(){

        $( '.js-continue' ).on( 'click keydown', function(){
            keydownClick();

            $( 'html' ).removeClass( 'is-warning' );
            $( '.alert-casing' ).fadeOut();
        });

    };

    $( window ).on({
        load: function(){
            ieJS.init();
        }
    });

}) (jQuery);
