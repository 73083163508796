var navJS = {};

( function($){

    var $container = $( '.navbar' );
    var navbar = $( '.navbar-collapse' );
    var body = $( 'body' );

    navJS.Togglebutton = function(){

        var close = $( '.menu-close' );
        var open = $( '.menu-open' );
        var currentScroll = 0;

        navbar.on( 'show.bs.collapse', function(){

            currentScroll = $( window ).scrollTop();

            setTimeout( function(){
                body.addClass( 'lock-position' );
            }, 500);

            body.addClass( 'is-open' );
            close.css({ 'display':'block' });
            open.css({ 'display':'none' });

        })
        .on( 'hide.bs.collapse', function(){

            open.css({ 'display':'block' });
            close.css({ 'display':'none' });
            body.removeClass( 'is-open lock-position' );
            $( window ).scrollTop( currentScroll );

        });
    };

    navJS.triggerClick = function(){

        if( Viewport.documentWidth().width > 767 ){
            if ( body.hasClass( 'lock-position' ) ) {
                $( '.navbar-toggle' ).trigger( 'click' );
            }
        }

    };

    navJS.MinimizeContent = function(){

        function navbarVisibility(){
            if ( $( window ).scrollTop() > 1 ) {
                body.addClass( 'is-minimize' );
            }else{
                body.removeClass( 'is-minimize' );
            }
        }
        navbarVisibility();

        $( window ).on( 'scroll', function(){
            navbarVisibility();
        });

    };

    $( window ).on({
        load: function(){
            setTimeout( function(){
                navJS.Togglebutton();
                navJS.triggerClick();
                navJS.MinimizeContent();
            }, 500 );
        },
        resize: function(){
            navJS.triggerClick();
        }
    });

}) (jQuery);
